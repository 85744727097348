<template>
  <div class="backup">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div class="backup__content">
        <div class="backup__form" @keyup.enter="deleteCurrentBackup()">
          <div class="l-col">
            <label class="typo__label">{{ $t('label') }}</label>
            <label class="typo__label"
              ><strong>{{ value }}</strong></label
            >
            <base-input
              v-model="nameBackup"
              :placeholder="$t('backup.placeholder')"
              size="small"
              :label="$t('backup.label')"
              class="backup-form__field backup-form__field--input"
            >
            </base-input>
          </div>
          <div class="backup__send">
            <div class="backup__send-container">
              <base-button class="backup__send-btn" @click="deleteCurrentBackup()"
                >{{ $t('btn.send') }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import showErrorModal from '@/mixins/showErrorModal';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
export default {
  name: 'DeleteCurrentBackup',
  components: {
    BaseInput,
  },
  mixins: [setFocus, showErrorModal, updateServerInfo],
  props: {
    backup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nameBackup: '',
      currentTaskUuid: '',
      timerCnt: 0,
    };
  },
  computed: {
    value() {
      return this.backup.name;
    },
    serverId() {
      return this.backup.serverId;
    },
    id() {
      return this.$route.name === 'BackupMain' ? '' : this.backup.instanceUuid;
    },
  },
  mounted() {},
  methods: {
    async deleteCurrentBackup() {
      this.showLoadingModal({});
      if (this.nameBackup !== this.backup.name) {
        this.showErrorModal(this.$t('confirm.fail'));
        this.$modals.close({ name: 'DeleteCurrentBackup' });
      } else {
        const data = await this.deleteBackup();
        if (this.$store.state.moduleVPS.moduleVpsOrder.stateBackup === 'success')
          this.showResModal(data);
        this.$modals.close({ name: 'DeleteCurrentBackup' });
        await this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo', this.id);
      }
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showErrorModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
        this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', '');
      }
      this.$route.name === 'InDetail' ? this.$router.push({ name: 'BackupMain' }) : null;
    },
    updateBackupInfo(callback) {
      //TODO DRY
      if (this.timerCnt < 5) this.timerCnt += 1;
      this.timerId = setTimeout(() => {
        this.fetchBackupInfo(this.currentTaskUuid).then(data => {
          this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', data.State);
          if (data.State === 'processing') {
            this.updateBackupInfo(callback, this.currentTaskUuid);
          } else {
            this.timerCnt = 0;
            if (callback && typeof callback === 'function') callback();
            clearTimeout(this.timerId);
            this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo', this.id);
            this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', '');
            this.showResModal(this.$t('backup.success', { name: this.value }));
          }
        });
      }, 1000 * this.timerCnt);
    },
    deleteBackup() {
      const payload = {
        serverid: this.backup.uuid,
        method: 'delete',
      };
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleVPS/moduleVpsOrder/setBackupAction', payload)
          .then(async response => {
            this.currentTaskUuid = response.Uuid;
            await this.updateBackupInfo();
            resolve('success');
          })
          .catch(reject => {
            this.showError(reject.response.data.message);
            this.$modals.close({ name: 'DeleteCurrentBackup' });
            this.$modals.close({ name: 'AddModal' });
          });
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": "Вы пытаетесь удалить: ",
    "confirm": {
      "fail" : "Не совпадают названия резервных копий - удаление отменено"
    },
    "backup": {
      "placeholder": "Введите имя удаляемого бекапа...",
      "label": "Введите имя копии для подтверждения",
      "success": "Резервная копия {name}, успешно удалена."
    },
    "btn": {
      "send": "Удалить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backup-form {
  &__field {
    &  {
      margin-top: 1.25rem;
    }
  }
}
.backup__send {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  &-btn {
    margin-top: 20px
    margin-right: 0;
    +breakpoint(ms-and-up) {
      margin-top: 60px;
    }
  }

  &-container{
    display: flex;
    align-items: flex-end;

  }

}
</style>
