<template>
  <div class="backup">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div class="backup__content">
        <div class="backup__form" @keyup.enter="addBackup()">
          <div class="l-col">
            <label class="typo__label">{{ $t('label') }}</label>
            <!--            <base-input-->
            <!--              v-if="tariff.domain"-->
            <!--              disabled-->
            <!--              :value="value"-->
            <!--              size="small"-->
            <!--              class="backup-form__field backup-form__field&#45;&#45;input"-->
            <!--            >-->
            <!--            </base-input>-->
            <label v-if="tariff.domain" class="backup-form__field">
              <strong> {{ value }} </strong>
            </label>
            <!--            v-model="services"-->
            <!--            <base-select-->
            <!--              v-if="options"-->
            <!--              :value="getServerId"-->
            <!--              select-label=""-->
            <!--              :hide-selected="true"-->
            <!--              :searchable="false"-->
            <!--              :options="options"-->
            <!--              size="medium"-->
            <!--              :allow-empty="false"-->
            <!--              class="backup-form__field"-->
            <!--              @input="onChange"-->
            <!--            >-->
            <!--              &lt;!&ndash;              :preselect-first="true"&ndash;&gt;-->
            <!--            </base-select>-->

            <base-input
              v-model="nameBackup"
              :placeholder="$t('backup.placeholder')"
              size="small"
              :label="$t('backup.label')"
              class="backup-form__field backup-form__field--input"
            >
            </base-input>
          </div>
          <div class="backup__send">
            <div class="backup__send-container">
              <base-button class="backup__send-btn" @click="addBackup"
                >{{ $t('btn.send') }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import { VpsTariff } from '@/models/BillMgr/VpsTariff';
import showErrorModal from '@/mixins/showErrorModal';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import storeMixin from '../mixins/index';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import translit from '@/mixins/translit';
export default {
  name: 'NewBackup',
  components: {
    BaseInput,
    // BaseSelect,
  },
  mixins: [setFocus, showErrorModal, updateServerInfo, translit, storeMixin],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof VpsTariff,
    },
    vpsList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      nameBackup: '',
      services: [],
      initialDataBackup: null,
      currentTaskUuid: '',
      timerCnt: 0,
    };
  },
  computed: {
    value() {
      return this.tariff.domain;
    },
    toLatin() {
      return this.translit(this.nameBackup);
    },
    validateNameBackups() {
      const regex = /\w/gm;
      return this.toLatin.length > 0
        ? this.toLatin.trim().replaceAll(' ', '_').match(regex).join('').slice(0, 40)
        : this.toLatin;
    },
    options() {
      return this.$store.state.moduleVPS.moduleVpsOrder.vpsList.map(i => i.Hostname);
    },
    values() {
      return this.$store.state.moduleVPS.moduleVpsOrder.vpsList || this.vpsList;
    },
    getValue() {
      if (this.values.length > 0) {
        return this.values.filter(i => i.Hostname === this.value).map(x => x.Uuid)[0];
      } else {
        return '';
      }
    },
    getServerId() {
      return this.values.filter(i => i.Uuid === this.serverId).map(x => x.Hostname)[0];
    },
  },
  mounted() {},
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
    async addBackup() {
      this.showLoadingModal({ title: this.$t('title') });
      const data = await this.createBackup();
      if (this.$store.state.moduleVPS.moduleVpsOrder.stateBackup === 'success')
        this.showResModal('success', data);
      this.$modals.close({ name: 'BackupModal' });
      await this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo', this.serverId);
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
        this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', '');
        // console.log('closeModal_res');
      }
    },
    updateBackupInfo(callback) {
      if (this.timerCnt < 5) this.timerCnt += 1;
      this.timerId = setTimeout(() => {
        this.fetchBackupInfo(this.currentTaskUuid).then(data => {
          this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', data.State);
          if (data.State === 'processing') {
            this.updateBackupInfo(callback, this.currentTaskUuid);
          } else if (data.State === 'failure') {
            this.showResModal(data.Error);
          } else {
            this.timerCnt = 0;
            if (callback && typeof callback === 'function') callback();
            clearTimeout(this.timerId);
            this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo', this.serverId);
            this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', '');
            this.showResModal(this.$t('backup.success', { name: this.validateNameBackups }));
          }
        });
      }, 1000 * this.timerCnt);
    },
    createBackup() {
      const payload = {
        System: false,
        // instanceuuid: this.getValue,
        instanceuuid: this.serverId,
        name: this.validateNameBackups,
        type: 'full',
        method: 'post',
      };
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('moduleVPS/moduleVpsOrder/setBackupAction', payload)
          .then(async response => {
            this.currentTaskUuid = response.Uuid;
            await this.updateBackupInfo();
            resolve('success');
          })
          .catch(reject => {
            this.showError(reject.response.data.message);
            this.$modals.close({ name: 'BackupModal' });
            this.$modals.close({ name: 'AddModal' });
          });
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Идет создание резервной копии",
    "label": "Сервер",
    "backup": {
      "placeholder": "Backup...",
      "success": "Бэкап {name} успешно создался",
      "label": "Введите имя бекапа"
    },
    "btn": {
      "send": "Создать"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backup {
  &-form {
    &__field {
      &  {
        margin-top: 1.25rem;
      }
    }
  }
  &__send {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;

    &-btn {
      margin-top: 20px
      margin-right: 0;
      +breakpoint(ms-and-up) {
        margin-top: 60px;
      }
    }

    &-container{
      display: flex;
      align-items: flex-end;

    }

  }
}
</style>
